import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

const styles = (theme) => ({
  table: {
    width: '100%',
  },
});

const OrdersTables = ({ classes, data }) => {
  const currency = data.length ? data[0].currency : '';
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>
            <div>№</div>
            <div>Date</div>
          </TableCell>
          <TableCell>Part Number</TableCell>
          <TableCell>Brand</TableCell>
          <TableCell>Delivery</TableCell>
          <TableCell>
            <div>Order Price</div>
            <div>{currency.trim()}</div>
          </TableCell>
          <TableCell>
            <div>Sale Price</div>
            <div>{currency.trim()}</div>
          </TableCell>
          <TableCell>Ordered</TableCell>
          <TableCell>Confirmed</TableCell>
          <TableCell>Ready</TableCell>
          <TableCell>Shipped</TableCell>
          <TableCell>Refused</TableCell>
          <TableCell>Reference</TableCell>
          <TableCell>ETA</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((value) => (
          <TableRow key={value.id}>
            <TableCell>
              <div>{value.orderId}</div>
              <div>{value.orderDate.slice(0, 10)}</div>
            </TableCell>
            <TableCell>{value.partNumber}</TableCell>
            <TableCell>{value.brand}</TableCell>
            <TableCell>{value.delivery}</TableCell>
            <TableCell>{value.priceOrder}</TableCell>
            <TableCell>{value.priceSale}</TableCell>
            <TableCell>{value.ordered}</TableCell>
            <TableCell>{value.purchased}</TableCell>
            <TableCell>{value.accepted}</TableCell>
            <TableCell>{value.shipped}</TableCell>
            <TableCell>{value.refused}</TableCell>
            <TableCell>{value.yourReference}</TableCell>
            <TableCell>{value.ETA.slice(0, 10)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

OrdersTables.defaultProps = {
  data: [],
};

export default withStyles(styles)(OrdersTables);
