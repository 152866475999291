
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import axios from 'axios';

import { Button, Tabs, Tab, Typography } from '@material-ui/core';

import {
  Page,
} from '../components';

const ManualsPage = ({ classes, history, location }) => {

      const [manSignUp, setManSignUp] = useState('');
      const [manOffers, setManOffers] = useState('');
      const [manQuote, setManQuote] = useState('');
      const [tab, setTab] = useState(0);

      function handleTabChange(event, value) {
        setTab(value);
      }

      useEffect(() => {

        const fetchManSignUp = async () => {
          const result = await axios.get('/manuals/manSignUp.html');
          setManSignUp(result.data);
        };
        fetchManSignUp();

        const fetchManOffers = async () => {
          const result = await axios.get('/manuals/manOffers.html');
          setManOffers(result.data);
        };
        fetchManOffers();

        const fetchManQuote = async () => {
          const result = await axios.get('/manuals/manQuote.html');
          setManQuote(result.data);
        };
        fetchManQuote();
      }, []);


    return (
    <Page>
      <Tabs
        className={classes.tabs}
        value={tab}
        centered
        onChange={handleTabChange}
      >
        <Tab
          label="Signing up"
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label="Quotation"
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
        <Tab
          label="Offers"
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        />
      </Tabs>


      { tab === 0 ? (
        <div >

          <div dangerouslySetInnerHTML={{ __html: manSignUp }} />

        </div>
      ) : tab === 1 ? (
        <div dangerouslySetInnerHTML={{ __html: manQuote }} />
      )
      : (
        <div>

          <div dangerouslySetInnerHTML={{ __html: manOffers }} />

        </div>

      )}



    </Page>
   )
};

const styles = (theme) => {
  const colors = theme.palette.custom.searchPage;
  return {
      tabs: {
        minHeight: 40,
      },
      tabRoot: {
        backgroundColor: colors.tabBackground,
        opacity: 1,
        minHeight: 40,
      },
      tabSelected: {
        backgroundColor: colors.tabSelectedBackground,
        color: '#fff',
      },

    dialog: {
      overflowY: 'auto',
      height: '70vh',
    },

    iframe: {
      border: 0,
      width: '100%',
      height: '99%',
    },
  };
};

export default withStyles(styles)(ManualsPage);
