import React from 'react';
import { TextField } from './index';

const StringField = ({ id, className, value, onChange }) => {
  
  let changeDelay = undefined;
  
  function handleChange(event) {
    
    clearTimeout(changeDelay);
    
    const { value } = event.target;
    if (onChange) {
      changeDelay=window.setTimeout(onChange, 500, id, value)
    } 
  }

  function handleBlur(event) {
    event.target.value = value;
  }

  return (
    <TextField
      className={className}
      defaultValue={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default StringField;
