import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import * as basketApi from '../api/basket-api';
import * as balanceApi from '../api/balance-api';
import { roundTo } from '../utils/math';
import { getCookie } from '../utils/cookies';

import { saveExcel } from '../utils/excel';

import {
  Page,
  BasketTable,
  BasketTotalTable,
  ActionsGroup,
  MessageDialog,
} from '../components';

const BasketPage = ({ classes }) => {
  const [isLoggedIn] = useState(!!getCookie('access_token'));
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [isUpdating, setUpdating] = useState(false);
  const [message, setMessage] = useState({});
  const [isMessageDialogOpen, setMessageDialogOpen] = useState(false);
  const [isFileUploading, setFileUploading] = useState(false);

  const confirmedData = useMemo(
    () => data.filter((item) => item.confirmed),
    [data]
  );

  const isDisabled = isUpdating || !confirmedData.length;

  useEffect(() => {
    if (isLoggedIn) fetchAll();
  }, [isLoggedIn]);

  useEffect(() => {
    if (totalData.balance === undefined) return;
    const items = data.filter((item) => item.confirmed);
    const total = {
      itemsForOrder: items.length,
      readyForOrder: roundTo(items.reduce((acc, item) => acc + item.amount, 0)),
      topupForOrder: 0,
      weightForOrder: roundTo(
        items.reduce((acc, item) => acc + item.quantity * item.weightKg, 0),
        3
      ),
    };
    total.topupForOrder = roundTo(
      -Math.min(totalData.availForOrder - total.readyForOrder, 0)
    );

    setTotalData({ ...totalData, ...total });
  }, [data]);

  async function fetchData() {
    const result = await basketApi.select();
    setData(result);
  }

  async function fetchTotalData() {
    const balance = await balanceApi.totals();
    setTotalData(balance);
  }

  async function fetchAll() {
    await fetchTotalData();
    await fetchData();
  }

  async function confirmItems(items, isConfirmed) {
    try {
      setUpdating(true);
      await basketApi.confirm(items, isConfirmed);
      await fetchData();
      setUpdating(false);
    } catch {
      alert('Cannot select item(s) due to the error');
      setUpdating(false);
    }
  }

  async function handleTableSelect(id) {
    const selectedItem = data.find((value) => value.id === id);
    await confirmItems([selectedItem], !selectedItem.confirmed);
  }

  function handleTableSelectAll(selectAll) {
    confirmItems(data, selectAll);
  }

  function handleMessageDialogClose() {
    setMessageDialogOpen(false);
  }

  function handleMessageDialogOpen() {
    showMessageDialog('This is a test message');
  }

  function showMessageDialog(aMsg) {
    setMessage(aMsg);
    setMessageDialogOpen(true);
  }

  async function handleQuantityChange(id, quantity) {
    let oldItem = null;
    let itemIndex = -1;
    try {
      setUpdating(true);
      itemIndex = data.findIndex((value) => value.id === id);
      oldItem = data[itemIndex];
      if (!itemIndex < 0) return;

      const newItem = {
        ...data[itemIndex],
        quantity,
      };

      // Apply changes in advance
      setData([
        ...data.slice(0, itemIndex),
        newItem,
        ...data.slice(itemIndex + 1),
      ]);

      await basketApi.addUpdate([newItem]);
      setUpdating(false);
    } catch (e) {
      alert('Cannot change quantity due to the error');

      // Revert changes
      if (itemIndex >= 0)
        setData([
          ...data.slice(0, itemIndex),
          oldItem,
          ...data.slice(itemIndex + 1),
        ]);

      setUpdating(false);
    }
    try {
      await fetchTotalData();
    } catch {
      // empty
    }
  }

  async function handleReferenceChange(id, yourReference) {
    let oldItem = null;
    let itemIndex = -1;

    try {
      setUpdating(true);

      itemIndex = data.findIndex((value) => value.id === id);
      oldItem = data[itemIndex];
      if (!itemIndex < 0) return;

      const newItem = {
        ...data[itemIndex],
        yourReference,
      };

      // Apply changes in advance
      setData([
        ...data.slice(0, itemIndex),
        newItem,
        ...data.slice(itemIndex + 1),
      ]);

      await basketApi.addUpdate([newItem]);
      setUpdating(false);
    } catch (e) {
      alert('Cannot change Reference due to the error');

      // Revert changes
      if (itemIndex >= 0)
        setData([
          ...data.slice(0, itemIndex),
          oldItem,
          ...data.slice(itemIndex + 1),
        ]);

      setUpdating(false);
    }
  }

  async function handleOrderClick() {
    try {
      const res = await basketApi.toOrder();

      try {
        if (res !== null && res.length !== 0) {
          if (parseInt(res[0].orderId) > 0)
            showMessageDialog(
              `Your order № ${res[0].orderId} in amount of ${res[0].currency}${res[0].amount} accepted.`
            );
          else if (res[0].orderId === '-1')
            showMessageDialog('Please contact us to activate your account');
          else if (res[0].orderId === '-2')
            showMessageDialog('Insufficient funds, please top up your balance');
          else if (res[0].orderId === '-3')
            showMessageDialog('No items for order');
          else if (res[0].orderId === '-4')
            showMessageDialog('The cart contains incorrect data');
          else
            showMessageDialog(
              `Your order not accepted due to an error (code = ${res[0].orderId})`
            );
        }
      } catch (e) {}

      await fetchData();
    } catch (e) {
      alert('Cannot order selected due to an error');
    }
  }

  async function handleDeleteClick() {
    try {
      await basketApi.delete_(confirmedData);
      await fetchData();
    } catch (err) {
      alert('Cannot delete selected due to the error');
    }
  }

  const columns = useMemo(() => [
    { name: 'Brand', width: 15 },
    { name: 'PartNumber', width: 15 },
    { name: 'Price', width: 10 },
    { name: 'Quantity', width: 10 },
    { name: 'Total', width: 10 },
    { name: 'Currency', width: 10 },
    { name: 'WeightKG', width: 15 },
    { name: 'Booking', width: 8 },
    { name: 'Delivery', width: 10 },
    { name: 'Description', width: 20 },
    { name: 'Reference', width: 15 },
    { name: 'ToOrder', width: 8 },
  ]);

  const outputData = useMemo(
    () =>
      data.map((value) => [
        value.brand,
        value.partNumber,
        value.price,
        value.quantity,
        value.amount,
        value.currency,
        value.weightKg,
        value.booking,
        value.tariff,
        value.description,
        value.yourReference,
        value.confirmed ? 'yes' : '',
      ]),
    [data]
  );

  async function handleToExcel() {
    await saveExcel('Cart', columns, outputData);
  }

  return (
    <Page>
      <div className={classes.totalTableContainer}>
        <BasketTotalTable data={totalData} />
      </div>
      <ActionsGroup className={classes.actionsGroup}>
        <ActionsGroup>
          <Button
            className={classes.orderButton}
            variant="contained"
            color="primary"
            disabled={isDisabled}
            onClick={handleOrderClick}
            //onClick={handleMessageDialogOpen}
          >
            Order Selected
          </Button>
          <Button
            className={classes.deleteButton}
            variant="contained"
            disabled={isDisabled}
            onClick={handleDeleteClick}
          >
            Delete Selected
          </Button>
        </ActionsGroup>
        <Button
          disabled={!data.length || isFileUploading}
          className={classes.excelButton}
          variant="contained"
          color="default"
          onClick={handleToExcel}
        >
          EXPORT
        </Button>
      </ActionsGroup>
      <div className={classes.tableContainer}>
        <BasketTable
          data={data}
          onQuantityChange={handleQuantityChange}
          onReferenceChange={handleReferenceChange}
          onSelect={handleTableSelect}
          onSelectAll={handleTableSelectAll}
        />
      </div>
      {isMessageDialogOpen && (
        <MessageDialog onClose={handleMessageDialogClose} message={message} />
      )}
    </Page>
  );
};

const styles = (theme) => {
  return {
    totalTableContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '24px',
    },
    tableContainer: theme.tableContainer,
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 24px',
    },
    actionsGroup: {
      justifyContent: 'space-between',
    },
    orderButton: {
      marginRight: theme.spacing(1),
    },
    excelButton: {},
    deleteButton: {},
  };
};

export default withStyles(styles)(BasketPage);
